<template>
  <div>
    <div
      v-for="(item, index) in contents"
      :key="index"
      class="content"
      @click="click(item)"
    >
      <div class="content-title">{{ item.title }}</div>
      <img :src="item.imgUrl" alt="" class="imgUrl" />
      <div class="clickNum">点击率 {{ item.click }}</div>
      <div class="date">{{ item.addTime }}</div>
      <div class="line" v-if="index != contents.length - 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hospitalNews",
  data() {
    return {
      news: {},
      contents: [],
    };
  },
  methods: {
    getArticle() {
      let postData = {
        parentId: this.$route.query.id,
      };
      this.$http
        .get("/api/content/infoByParentId", postData)
        .then((res) => {
          this.news = res.data.data.find((item) => item.title == "院内新闻");
        })
        .then(() => {
          this.$http
            .get("/api/content/infoByParentId", {
              parentId: this.news.id,
            })
            .then((result) => {
              this.contents = result.data.contents;
            });
        });
    },
    click(item) {
      window.sessionStorage.setItem("currentArticle", JSON.stringify(item));
      this.$router.push({
        path: "/newsDetail",
      });
    },
  },
  mounted() {
    this.getArticle();
  },
};
</script>

<style scoped>
.content {
  height: 2rem;
  width: 10rem;
  box-sizing: border-box;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
}
.content-title {
  width: 6rem;
  text-align: left;
  float: left;
  font-size: 0.35rem;
}
.imgUrl {
  width: 2.5rem;
  height: 2rem;
  float: right;
}
.clickNum {
  float: left;
  position: absolute;
  left: 0.5rem;
  bottom: 0.1rem;
  font-size: 0.3rem;
  color: rgba(153, 153, 153, 1);
}
.date {
  float: right;
  position: absolute;
  bottom: 0.1rem;
  left: 2.2rem;
  font-size: 0.3rem;
  color: rgba(153, 153, 153, 1);
}
.line {
  float: right;
  width: 100%;
  height: 1px;
  margin-top: 5px;
  background-color: #afafaf;
}
</style>
